import React from 'react'
import { gql } from '@apollo/client'
import { SimpleCtaFragment } from './__generated__/simpleCta'
import { SimpleCta as BaseSimpleCta } from '../../../components/simplecta/SimpleCta'
import { isNotNull } from '../../../utils/nonNull'

export type SimpleCtaProps = {
  data: SimpleCtaFragment[]
}

export const SimpleCta: React.VFC<SimpleCtaProps> = ({ data }) => {
  const cta = data[0]

  if (!cta) {
    return null
  }

  return (<>

    <BaseSimpleCta>
      <BaseSimpleCta section>
        <BaseSimpleCta title>{cta.ctaText}</BaseSimpleCta>
        {cta.subtext && <BaseSimpleCta subtitle>{cta.subtext}</BaseSimpleCta>}
        {cta.smallSubText && <BaseSimpleCta content>{cta.smallSubText}</BaseSimpleCta>}
      </BaseSimpleCta>
      {cta.ctaButtonLink && cta.ctaButtonLink.__typename === 'linkField_Link' && isNotNull(cta.ctaButtonLink.url) && isNotNull(cta.ctaButtonLink.customText) && (
        <BaseSimpleCta cta padding={cta.subtext? true : ( cta.smallSubText ? true : false)} href={cta.ctaButtonLink.url} target={ cta.ctaButtonLink.target === '_blank' ? '_blank' : '_self'}>{cta.ctaButtonLink.customText}</BaseSimpleCta>
      )}
    </BaseSimpleCta>

  </>
  )
}

export const SIMPLE_CTA_FRAGMENT = gql`
  fragment SimpleCtaFragment on simpleCta_BlockType {
    ctaText
    subtext
    smallSubText
    ctaButtonLink {
      url
      customText
      target
    }
  }
`